import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

// eslint-disable-next-line
import { langDefault, langAvailable, segments } from '@/inc/app.config'
import Root from '@/views/Root.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

let routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: 'v-home' */ '@/views/Home.vue'),
    meta: {
      ssr: true,
      lightHeaderTheme: true,
    },
  },
  {
    path: `${formatSegment('references')}/:slug/`,
    name: 'ReferenceSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-reference' */ '@/views/pages/ReferenceSingle.vue'
      ),
    meta: {
      endpoint: 'reference',
      ssr: true,
    },
  },
  {
    path: `${formatSegment('references')}/`,
    name: 'ReferencesListing',
    component: () =>
      import(
        /* webpackChunkName: 'v-references' */ '@/views/pages/ReferencesListing.vue'
      ),
    meta: {
      endpoint: 'references',
      ssr: true,
    },
  },
  {
    path: `${formatSegment('news')}/:slug/`,
    name: 'NewsSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-news-single' */ '@/views/pages/NewsSingle.vue'
      ),
    meta: {
      endpoint: 'news',
      ssr: true,
    },
  },
  {
    path: `${formatSegment('news')}/`,
    name: 'NewsListing',
    component: () =>
      import(
        /* webpackChunkName: 'v-news-listing' */ '@/views/pages/NewsListing.vue'
      ),
    meta: {
      endpoint: 'news',
      ssr: true,
    },
  },
  {
    path: `${formatSegment('solution')}/:slug/`,
    name: 'SolutionSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-solution-single' */ '@/views/pages/SolutionSingle.vue'
      ),
    meta: {
      endpoint: 'solution',
      ssr: true,
    },
  },
  {
    path: `${formatSegment('satisfaction')}/`,
    name: 'Satisfaction',
    component: () =>
      import(
        /* webpackChunkName: 'v-satisfaction' */ '@/views/pages/Satisfaction.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  // Jobs
  {
    path: `${formatSegment('job')}/:slug/`,
    name: 'JobSingle',
    component: () =>
      import(
        /* webpackChunkName: 'v-job-single' */ '@/views/pages/JobSingle.vue'
      ),
    meta: {
      endpoint: 'job',
      ssr: true,
    },
  },
  {
    path: '/:slug/:sub?',
    name: 'Page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/*',
    name: 'All',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: Root as VueConstructor<Vue>,
      meta: {
        // Do not ScrollTop all the time for all routes because it will not work well with page transitions
        // We'll let the page transition handle how and when it wants to scroll up
        scrollTop: false,
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export { routes }
