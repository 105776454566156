import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/inc/store'

type TransitionStatus = 'none' | 'leaving' | 'left' | 'entering' | 'entered'

export interface UIState {
  isNavigating: boolean
  transitionStatus: TransitionStatus
  modalVisible: boolean
  modalContent: number
}

function getDefaultState(): UIState {
  return {
    isNavigating: false,
    transitionStatus: 'none',
    modalVisible: false,
    modalContent: 0,
  }
}

const state: UIState = getDefaultState()
const getters: GetterTree<UIState, RootState> = {
  modalVisible: state => state.modalVisible,
  modalContent: state => state.modalContent,
}
const mutations: MutationTree<UIState> = {
  NAVIGATION_START(state) {
    state.isNavigating = true
  },
  NAVIGATION_END(state) {
    state.isNavigating = false
  },
  TRANSITION_STATUS(state, payload) {
    state.transitionStatus = payload
  },
  SET_PANEL(state, payload: number) {
    state.modalVisible = true
    state.modalContent = payload
    document.body.classList.add('no-scroll')
  },
  UNSET_PANEL(state) {
    state.modalVisible = false
    state.modalContent = 0
    document.body.classList.remove('no-scroll')
  },
  PREVIOUS_PANEL(state) {
    state.modalContent -= 1
  },
  NEXT_PANEL(state) {
    state.modalContent += 1
  },
}
const actions: ActionTree<UIState, RootState> = {
  navigationStart({ commit }) {
    commit('NAVIGATION_START')
  },
  navigationEnd({ commit }) {
    commit('NAVIGATION_END')
  },
}

const ui: Module<UIState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default ui
