





import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import gsap from 'gsap'
import SplitText from '@/inc/vendor/gsap/SplitText'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'LineSplitter',
  props: {
    type: {
      required: false,
      type: String,
      default: 'div',
    },
    content: {
      required: false,
      type: String,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const rootRef = ref()
    const targets = ref()
    let TLIn: gsap.core.Tween | undefined = undefined
    let TLOut: gsap.core.Tween | undefined = undefined

    const splitText = () => {
      if (props.animate && motionOK()) {
        // Get anim targets
        const splitter = new SplitText(rootRef.value, {
          type: 'lines',
          tag: 'span',
        })
        targets.value = splitter.lines

        // Add wrapper
        // eslint-disable-next-line no-new
        new SplitText(rootRef.value, {
          type: 'lines',
          linesClass: 'v-c',
          tag: 'span',
        })

        gsap.set(targets.value, {
          opacity: 0.5,
          yPercent: 100,
        })
      }
    }

    const reset = () => {
      TLIn = undefined
      TLOut = undefined
      splitText()
    }

    // How we use TextSplitter makes us loose reactivity.
    // To fix that, we watch content prop change and reset component when it change
    watch(
      () => props.content,
      (newContent, oldContent) => {
        if (newContent !== oldContent) {
          rootRef.value.textContent = newContent
          reset()
        }
      }
    )

    onMounted(() => {
      splitText()
    })

    const getTween = () => {
      if (TLIn === undefined) {
        TLIn = gsap.to(targets.value, {
          opacity: 1,
          yPercent: 0,
          stagger: 0.075,
          duration: 1,
          ease: 'power4.out',
        })
      }

      return TLIn
    }

    const getTweenOut = () => {
      if (TLOut === undefined) {
        TLOut = gsap.to(targets.value, {
          opacity: 0.5,
          yPercent: 100,
          stagger: 0.075,
          duration: 0.5,
          ease: 'power4.out',
        })
      }

      return TLOut
    }

    return {
      rootRef,
      getTween,
      getTweenOut,
    }
  },
})
